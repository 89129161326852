import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useCallback, useState } from "react";
import { Layout } from "../../components/Layout";
import { useTranslation } from "react-i18next";

export const Graphics = () => {
  const { t } = useTranslation();

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = [
    {
      src: "/media/graphics/1.jpg",
      title: "Портрет девушки",
      width: 3,
      height: 2,
    },
    {
      src: "/media/graphics/2.jpg",
      title: "Портрет художника",
      width: 3,
      height: 2,
    },
    {
      src: "/media/graphics/3.jpg",
      title: "У источника",
      width: 3,
      height: 2,
    },
    {
      src: "/media/graphics/4.jpg",
      title: "Танец",
      width: 3,
      height: 2,
    },
    {
      src: "/media/graphics/5.jpg",
      title: "Гимнастки",
      width: 3,
      height: 2,
    },
    {
      src: "/media/graphics/6.jpg",
      title: "Гагаузкая семья",
      width: 3,
      height: 2,
    },
    {
      src: "/media/graphics/7.jpg",
      title: "Цветочницы",
      width: 3,
      height: 2,
    },
    {
      src: "/media/graphics/8.jpg",
      title: "Сбор винограда",
      width: 3,
      height: 2,
    },
  ];

  return (
    <Layout title={t("gallery.graphics")}>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Layout>
  );
};
