import { useTranslation } from "react-i18next";
import { Layout } from "../components/Layout";

export const Home = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="flex flex-col gap-8 py-4 text-center">
        <h1 className="font-semibold text-2xl lg:text-5xl">{t("main.h1")}</h1>
        <h2 className="font-medium text-base lg:text-2xl">
          {t("main.description")}
        </h2>
      </div>

      <div className="flex gap-10 pt-10">
        <div className="w-full flex justify-center">
          <img src="media/home.jpeg" alt="contacts" />
        </div>
      </div>
    </Layout>
  );
};
