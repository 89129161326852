import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "ru",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      ru: {
        translation: {
          author: "Иван Каварналы",
          main: {
            title: "Главная",
            h1: "Художник Иван Каварналы",
            description:
              "Живопись. Графика. Акварель. Преподаватель пластической анатомии.",
            descriptionShort: "Живопись. Графика. Акварель.",
          },
          gallery: {
            title: "Галерея",
            portrait: "Портрет",
            stillLife: "Натюрморт",
            landscapes: "Пейзажи",
            graphics: "Графика",
            nude: "Ню",
          },
          about: {
            title: "О художнике",
            1: "Профессиональный художник и педагог, с многолетним стажем преподавания пластической анатомии, живописи, рисунка и графики.",
            2: "Член союза художников Республики Молдова.",
            3: "Участник республиканских и международных выставок.",
            4: 'Его работы находятся в музеях и частных коллекциях Турции, Германии, России и в других странах. Закончил Республиканское художественно-педагогическое училище им."И.Репина" в г. Кишинёв а также Харьковский художественно-промышленный институт.',
            5: "Иван Каварналы работает в различных жанрах: портрет, натюрморт, пейзаж, графика и ню.",
            6: "Принимает заказы на портреты и даёт частные уроки.",
          },
          contacts: {
            title: "Контакты",
            address: "Молдова, Кишинев",
          },
        },
      },
      en: {
        translation: {
          author: "Ivan Kavarnali",
          main: {
            title: "Main",
            h1: "Artist Ivan Kavarnali",
            description:
              "Painting. Graphic arts. Watercolor. Teacher of plastic anatomy.",
            descriptionShort: "Painting. Graphic arts. Watercolor.",
          },
          gallery: {
            title: "Gallery",
            portrait: "Portrait",
            stillLife: "Still life",
            landscapes: "Landscapes",
            graphics: "Graphic arts",
            nude: "Nude",
          },
          about: {
            title: "About",
            1: "Professional artist and teacher, with many years of experience in teaching plastic anatomy, painting, drawing and graphics.",
            2: "Member of the Union of Artists of the Republic of Moldova.",
            3: "Participant in republican and international exhibitions.",
            4: "His works are in museums and private collections in Turkey, Germany, Russia and other countries. He graduated from the Republican Art and Pedagogical School named after I. Repin in Chisinau as well as the Kharkov Art and Industrial Institute.",
            5: "Ivan Kavarnaly works in various genres: portrait, still life, landscape, graphics and nudes.",
            6: "Accepts orders for portraits and gives private lessons.",
          },
          contacts: {
            title: "Contacts",
            address: "Moldova, Chisinau",
          },
        },
      },
    },
  });

export default i18n;
