import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useCallback, useState } from "react";
import { Layout } from "../../components/Layout";
import { useTranslation } from "react-i18next";

export const StillLife = () => {
  const { t } = useTranslation();

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = [
    {
      src: "/media/still-life/1.jpg",
      title: "Этюд",
      width: 3,
      height: 2,
    },
    {
      src: "/media/still-life/2.jpg",
      title: "Фрукты на фоне самовара. Акварель.58х67",
      width: 3,
      height: 2,
    },
    {
      src: "/media/still-life/3.jpg",
      title: "Арбуз. К/М. 50х70",
      width: 3,
      height: 2,
    },
    {
      src: "/media/still-life/4.jpg",
      title: "Натюрморт. Х/М. 50х42",
      width: 3,
      height: 2,
    },
    {
      src: "/media/still-life/5.jpg",
      title: "Красный рак. К/М. 48х58",
      width: 3,
      height: 2,
    },
    {
      src: "/media/still-life/6.jpg",
      title: "Фрукты.К/А. 58х67",
      width: 3,
      height: 2,
    },
    {
      src: "/media/still-life/7.jpg",
      title: "Чайный набор. Х/М. 76х57",
      width: 3,
      height: 2,
    },
    {
      src: "/media/still-life/8.jpg",
      title: "Цветы. Х/М. 73х60",
      width: 3,
      height: 2,
    },
    {
      src: "/media/still-life/9.jpg",
      title: "Осенние цветы. Х/М. 68х51",
      width: 3,
      height: 2,
    },
    {
      src: "/media/still-life/10.jpg",
      title: "Цветы в вазе. Х/М. 70х52",
      width: 3,
      height: 2,
    },
    {
      src: "/media/still-life/11.jpg",
      title: "Цветы в кувшине. Х/М. 70х57",
      width: 3,
      height: 2,
    },
    {
      src: "/media/still-life/12.jpg",
      title: "Цветы на фоне окна. Х/М. 50х40",
      width: 3,
      height: 2,
    },
    {
      src: "/media/still-life/13.jpg",
      title: "Цветы и женский торс. Х/М. 50х42",
      width: 3,
      height: 2,
    },
  ];

  return (
    <Layout title={t("gallery.stillLife")}>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Layout>
  );
};
