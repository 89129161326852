import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export const Header = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const lngs = {
    ru: { nativeName: "Руский" },
    en: { nativeName: "English" },
  };

  const links = [
    { to: "/gallery", title: t("gallery.title") },
    { to: "/about", title: t("about.title") },
    { to: "/contacts", title: t("contacts.title") },
  ];

  return (
    <header className="bg-white">
      <div className="mx-auto flex h-20 max-w-screen-xl items-center gap-8 px-0 sm:px-0 lg:px-0 pb-6">
        <a className="block text-teal-600" href="/">
          <img width="90" src="/logo.png" alt="logo" />
        </a>

        <div className="flex flex-1 items-center justify-end md:justify-center">
          {/* Desktop */}
          <nav aria-label="Global" className="hidden md:block">
            <ul className="flex items-center gap-6 text-sm">
              {links.map((item) => (
                <li
                  key={item.to}
                  className={`text-gray-500 transition hover:text-gray-500/75 ${
                    location.pathname === item.to ? "font-bold" : ""
                  }`}
                >
                  <Link isActive={false} to={item.to}>
                    {item.title}
                  </Link>{" "}
                </li>
              ))}
            </ul>
          </nav>

          {/* Mobile */}
          <nav className="group relative block md:hidden">
            <button className="h-full p-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700">
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 18L20 18"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M4 12L20 12"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M4 6L20 6"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>

            <div
              className="group-hover:block dropdown-menu absolute hidden h-auto end-0 z-10 w-36 rounded-md border-gray-100 bg-white shadow-lg"
              role="menu"
            >
              <div className="p-2 group-hover:block">
                {links.map((item) => (
                  <Link key={item.to} to={item.to}>
                    <li className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                      {item.title}
                    </li>
                  </Link>
                ))}
              </div>

              <div className="pl-4 p-2 flex gap-4 justify-center">
                {Object.keys(lngs).map((lng) => (
                  <button
                    className="text-xs"
                    key={lng}
                    style={{
                      fontWeight:
                        i18n.resolvedLanguage === lng ? "bold" : "normal",
                    }}
                    type="submit"
                    onClick={() => i18n.changeLanguage(lng)}
                  >
                    {lngs[lng].nativeName}
                  </button>
                ))}
              </div>
            </div>
          </nav>
        </div>

        <div className="hidden lg:flex items-center gap-4">
          <div className="flex gap-2">
            {Object.keys(lngs).map((lng) => (
              <button
                className="text-xs"
                key={lng}
                style={{
                  fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
                }}
                type="submit"
                onClick={() => i18n.changeLanguage(lng)}
              >
                {lngs[lng].nativeName}
              </button>
            ))}
          </div>
        </div>
      </div>
    </header>
  );
};
