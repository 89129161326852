import { Layout } from "../components/Layout";
import { Category } from "../components/Category";
import { useTranslation } from "react-i18next";

export const PhotoGallery = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t("gallery.title")}>
      <ul className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <Category
          image="/media/portrait/1.webp"
          title={t("gallery.portrait")}
          to="portrait"
        />
        <Category
          image="/media/still-life/1.jpg"
          title={t("gallery.stillLife")}
          to="still-life"
        />
        <Category
          image="/media/landscapes/1.jpg"
          title={t("gallery.landscapes")}
          to="landscapes"
        />
        <Category
          image="/media/graphics/1.jpg"
          title={t("gallery.graphics")}
          to="graphics"
        />
        <Category
          image="/media/nude/4.jpg"
          title={t("gallery.nude")}
          to="nude"
        />
      </ul>
    </Layout>
  );
};
