import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useCallback, useState } from "react";
import { Layout } from "../../components/Layout";
import { useTranslation } from "react-i18next";

export const Nude = () => {
  const { t } = useTranslation();

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = [
    {
      src: "/media/nude/1.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "/media/nude/2.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "/media/nude/3.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "/media/nude/4.jpg",
      width: 6,
      height: 2,
    },
    {
      src: "/media/nude/5.jpg",
      width: 4,
      height: 2,
    },
    {
      src: "/media/nude/6.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "/media/nude/7.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "/media/nude/8.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "/media/nude/9.jpg",
      width: 3,
      height: 2,
    },
  ];

  return (
    <Layout title={t("gallery.nude")}>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Layout>
  );
};
