import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const links = [
    { to: "/gallery", title: t("gallery.title") },
    { to: "/about", title: t("about.title") },
    { to: "/contacts", title: t("contacts.title") },
  ];

  return (
    <footer class="bg-white">
      <div class="relative mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-0 lg:pt-32">
        <div class="lg:flex lg:items-end lg:justify-between">
          <div>
            <div class="flex justify-center text-teal-600 lg:justify-start">
              <a className="block text-teal-600" href="/">
                <img width="90" src="/logo.png" alt="logo" />
              </a>
            </div>

            <p class="mx-auto mt-6 max-w-md text-center leading-relaxed text-gray-500 lg:text-left">
              {t("main.descriptionShort")}
            </p>
          </div>

          <ul class="mt-12 flex flex-wrap justify-center gap-6 md:gap-8 lg:mt-0 lg:justify-end lg:gap-12">
            {links.map((item) => (
              <li
                key={item.to}
                className={`text-gray-700 transition hover:text-gray-700/75 ${
                  location.pathname === item.to ? "font-bold" : ""
                }`}
              >
                <Link isActive={false} to={item.to}>
                  {item.title}
                </Link>{" "}
              </li>
            ))}
          </ul>
        </div>

        <p class="mt-12 text-center text-sm text-gray-500">
          {t("author")} © {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
};
