import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useCallback, useState } from "react";
import { Layout } from "../../components/Layout";
import { useTranslation } from "react-i18next";

export const Portrait = () => {
  const { t } = useTranslation();

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = [
    {
      src: "/media/portrait/1.webp",
      title: "Портрет художницы. К/М. 66х50",
      width: 3,
      height: 2,
    },
    {
      src: "/media/portrait/2.webp",
      title: "Женский портрет. Х/М. 73х60",
      width: 3,
      height: 2,
    },
    {
      src: "/media/portrait/3.webp",
      title: "Портрет Анжелы. К/М. 80х60",
      width: 3,
      height: 2,
    },
    {
      src: "/media/portrait/4.webp",
      title: "Портрет Ольги Ивановой. Х/М. 80х58",
      width: 3,
      height: 2,
    },
    {
      src: "/media/portrait/5.webp",
      title: "Сулейман Демерель, 9ый Президент Турции. Х/М. 92х73",
      width: 3,
      height: 2,
    },
    {
      src: "/media/portrait/6.webp",
      title: "Автопортрет",
      width: 3,
      height: 2,
    },
    {
      src: "/media/portrait/7.webp",
      title: "Портрет гагаузки. Х/М. 59х44",
      width: 3,
      height: 2,
    },
    {
      src: "/media/portrait/8.webp",
      title: "Девушка с веером. К/М. 80х60",
      width: 3,
      height: 2,
    },
    {
      src: "/media/portrait/9.webp",
      title: "Женский портрет в овале. К/М. 97х70",
      width: 3,
      height: 2,
    },
    {
      src: "/media/portrait/10.webp",
      title: "Портрет девушки. К/П. 62х57",
      width: 3,
      height: 2,
    },
  ];

  return (
    <Layout title={t("gallery.portrait")}>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Layout>
  );
};
