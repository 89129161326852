import { Footer } from "./Footer";
import { Header } from "./Header";

export const Layout = ({ children, title }) => {
  return (
    <div className="mx-auto lg:min-w-[1024px] xl:min-w-[1280px] max-w-lg pt-8 p-6 text-sm lg:text-base">
      <Header />

      {title ? (
        <h2 class="mb-6 text-xl font-bold text-gray-900 sm:text-3xl">
          {title}
        </h2>
      ) : null}
      {children}

      <Footer />
    </div>
  );
};
