import { Routes, Route } from "react-router-dom";
import { PhotoGallery } from "./pages/Gallery";
import "./i18n";
import "./App.css";
import { Contacts } from "./pages/Contacts";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Portrait } from "./pages/gallery/Portrait";
import { StillLife } from "./pages/gallery/StillLife";
import { Landscapes } from "./pages/gallery/Landscapes";
import { Graphics } from "./pages/gallery/Graphics";
import { Nude } from "./pages/gallery/Nude";

function App() {
  return (
    <div className="container mx-auto">
      <Routes>
        <Route index element={<Home />} />
        <Route path="*" element="/" />
        <Route path="gallery" element={<PhotoGallery />} />
        <Route path="gallery/portrait" element={<Portrait />} />
        <Route path="gallery/still-life" element={<StillLife />} />
        <Route path="gallery/landscapes" element={<Landscapes />} />
        <Route path="gallery/portrait" element={<Portrait />} />
        <Route path="gallery/graphics" element={<Graphics />} />
        <Route path="gallery/nude" element={<Nude />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="about" element={<About />} />
      </Routes>
    </div>
  );
}

export default App;
