import { useTranslation } from "react-i18next";
import { Layout } from "../components/Layout";

export const Contacts = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t("contacts.title")}>
      <div className="flex gap-10 flex-col-reverse xl:flex-row">
        <div className="xl:w-1/3">
          <img src="media/contacts.jpg" alt="contacts" />
        </div>

        <div className="w-full">
          <div className="flex flex-col gap-4">
            <p>{t("contacts.address")}</p>
            <p className="pt-4">
              <a href="tel:+37379268304">+37379268304</a> ·{" "}
              <a href="tel:+37379475970">+37379475970</a> ·{" "}
              <a href="tel:+37322213847">+37322213847</a>
            </p>

            <p className="pt-4">
              <a href="mailto:artkavarnali@mail.ru">artkavarnali@mail.ru</a> ·{" "}
              <a href="mailto:kavarnali@mail.ru">kavarnali@mail.ru</a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
