import { useTranslation } from "react-i18next";
import { Layout } from "../components/Layout";

export const About = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t("about.title")}>
      <div className="flex gap-10 flex-col-reverse lg:flex lg:flex-row">
        <div className="lg:w-1/3">
          <img src="media/about.jpg" alt="about" />
        </div>

        <div className="lg:w-2/3">
          <div className="flex flex-col gap-4">
            <p>{t("about.1")}</p>
            <p>{t("about.2")}</p>
            <p>{t("about.3")}</p>
            <p>{t("about.4")}</p>
            <p>{t("about.5")}</p>
            <p>{t("about.6")}</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
